import store from "./../store";

export default {
  data: () => {
    return {
      scroll_position: 0,
      scroll_direction: "",
    };
  },

  methods: {
    openSiteSelector() {
      store.dispatch("status/openSiteSelector");
    },

    handleScroll() {
      this.scroll_direction =
        document.documentElement.scrollTop > this.scroll_position
          ? "down"
          : "up";
      this.scroll_position = document.documentElement.scrollTop;

      if (this.scroll_direction === "down") {
        document.body.classList.add("scrolled");
        if (this.scroll_position >= 112 && window.innerWidth > 768) {
          document
            .getElementById("p-header")
            ?.classList.add("p-header--scrolled");
        }
        if (this.scroll_position >= 96 && window.innerWidth <= 768) {
          document
            .getElementById("p-header")
            ?.classList.add("p-header--scrolled");
        }
      }
      if (this.scroll_direction === "up") {
        document.body.classList.remove("scrolled");
        if (this.scroll_position <= 112 && window.innerWidth > 768) {
          document
            .getElementById("p-header")
            ?.classList.remove("p-header--scrolled");
        }
        if (this.scroll_position <= 96 && window.innerWidth <= 768) {
          document
            .getElementById("p-header")
            ?.classList.remove("p-header--scrolled");
        }
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
