const state = () => ({
	commerceFilters: null,
	commerceSorting: null,
	commerceSubscription: false,
	commerceCart: {},
});

// Getters
const getters = {};

// Actions
const actions = {
	cart({ commit, state }, cart) {
		commit('setCommerceCart', cart);
	},
	cartField({ commit, state }, object) {
		commit('setCommerceCartField', object);
	},
	subscription({ commit, state }, subscription) {
		commit('setCommerceSubscription', subscription);
	},
	filters({ commit, state }, filter) {
		commit('setCommerceFilters', filter);
	},
	sorting({ commit, state }, sort) {
		commit('setCommerceSorting', sort);
	},
};

// Mutations
const mutations = {
	setCommerceSubscription(state, value) {
		state.commerceSubscription = value;
	},
	setCommerceCart(state, value) {
		state.commerceCart = value;
	},
	setCommerceCartField(state, object) {
		state.commerceCart[object.field] = object.value;
	},
	setCommerceFilters(state, value) {
		state.commerceFilters = value;
	},
	setCommerceSorting(state, value) {
		state.commerceSorting = value;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
