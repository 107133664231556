const state = () => ({
  siteSelector: false,
  variantSelector: false,
  variantData: null,
});
// Actions
const actions = {
  // SITE SELECTOR
  closeSiteSelector({ commit, state }) {
    commit("setSiteSelector", false);
  },
  openSiteSelector({ commit, state }) {
    commit("setSiteSelector", true);
  },

  // VARIANT SELECTOR
  closeVariantSelector({ commit, state }) {
    commit("setVariantSelector", false);
    commit("setVariantData", null);
  },
  openVariantSelector({ commit, state }, data) {
    commit("setVariantSelector", true);
    commit("setVariantData", data);
  },
};

// Mutations
const mutations = {
  setSiteSelector(state, value) {
    state.siteSelector = value;
  },
  setVariantSelector(state, value) {
    state.variantSelector = value;
  },
  setVariantData(state, value) {
    state.variantData = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
