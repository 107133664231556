import { createStore, createLogger } from "vuex";
import VuexPersistence from "vuex-persist";

//Modules
import app from "./modules/site";
import commerce from "./modules/commerce";
import status from "./modules/status";
import translations from "./modules/translations";

const debug = process.env.NODE_ENV !== "production";

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.sessionStorage,
});

export default createStore({
  modules: {
    app,
    commerce,
    status,
    translations,
  },
  strict: debug,
  plugins: debug ? [createLogger(), vuexLocal.plugin] : [vuexLocal.plugin],
});
