const state = () => ({
	scrollPosition: 0,
	csrfToken: '',
	siteUrl: '',
	favorites: {},
	language: '',
});

// Getters
const getters = {
	scrollPosition: (state, getters) => {
		return state.scrollPosition;
	},
};

// Actions
const actions = {
	favorites({ commit, state }, favorites) {
		commit('setFavorites', favorites);
	},
};

// Mutations
const mutations = {
	setCsrfToken(state, token) {
		state.csrfToken = token;
	},
	setSiteUrl(state, value) {
		state.siteUrl = value;
	},
	setFavorites(state, value) {
		state.favorites = value;
	},
	setLanguage(state, value) {
		state.language = value
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
