import { mapState } from 'vuex';

//* APP
import { createApp, defineAsyncComponent } from "vue"

//* COMPONENTS
import axios from "axios";
import VideoBackground from 'vue-responsive-video-background-player';
import store from "./store";
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation, Pagination, EffectFade, Thumbs } from 'swiper/core';

//* MIXINS
import { translations } from './mixins/translations';
import site from './mixins/site';

//* STYLE
import 'swiper/swiper.scss';
import "@qd/vue-slider/dist/style.css";

//* DECLARE
declare const window: any;

//* MAIN
const main = async () => {
    const app = createApp({
      delimiters: ['${', '}'],
      components: {
        //* COMMERCE
        CatalogVariant: defineAsyncComponent(() => import(/* webpackChunkName: "CatalogVariant" */ '@/vue/components/commerce/objects/CatalogVariant.vue')),

        CatalogProduct: defineAsyncComponent(() => import(/* webpackChunkName: "CatalogProduct" */ '@/vue/components/product/CatalogProduct.vue')),
        CommerceProduct: defineAsyncComponent(() => import(/* webpackChunkName: "CommerceProduct" */ '@/vue/components/commerce/Product.vue')),
        CommerceVoucher: defineAsyncComponent(() => import(/* webpackChunkName: "CommerceVoucher" */ '@/vue/components/commerce/Voucher.vue')),
        CommerceProductRecommendations: defineAsyncComponent(() => import(/* webpackChunkName: "ProductRecommendations" */ '@/vue/components/commerce/objects/ProductRecommendations.vue')),
        Newsletter: defineAsyncComponent(() => import(/* webpackChunkName: "Newsletter" */ '@/vue/components/commerce/Newsletter.vue')),
        CommerceFavorite: defineAsyncComponent(() => import(/* webpackChunkName: "CommerceFavorite" */ '@/vue/components/commerce/Favorite.vue')),
        CommerceReviews: defineAsyncComponent(() => import(/* webpackChunkName: "CommerceReviews" */ '@/vue/components/commerce/Reviews.vue')),

        // CART
        Cart: defineAsyncComponent(() => import(/* webpackChunkName: "Cart" */ '@/vue/components/commerce/Cart.vue')),
        CartLineItem: defineAsyncComponent(() => import(/* webpackChunkName: "CartLineItem" */ '@/vue/components/commerce/objects/CartLineItem.vue')),
        CommerceCheckout: defineAsyncComponent(() => import(/* webpackChunkName: "CommerceCheckout" */ '@/vue/components/commerce/Checkout.vue')),

        CartAddSubscription: defineAsyncComponent(() => import(/* webpackChunkName: "SubscriptionAddToCart" */ '@/vue/components/objects/CartAddSubscription.vue')),
        CartAddPurchasable: defineAsyncComponent(() => import(/* webpackChunkName: "CartAddPurchasable" */ '@/vue/components/objects/CartAddPurchasable.vue')),

        //* ACCOUNT
        AccountDetails: defineAsyncComponent(() => import(/* webpackChunkName: "AccountDetails" */ '@/vue/components/account/AccountDetails.vue')),
        AccountOrders: defineAsyncComponent(() => import(/* webpackChunkName: "AccountOrders" */ '@/vue/components/account/AccountOrders.vue')),
        AccountSubscription: defineAsyncComponent(() => import(/* webpackChunkName: "AccountSubscription" */ '@/vue/components/account/AccountSubscription.vue')),

        //* BLOCKS
        BlockVideo: defineAsyncComponent(() => import(/* webpackChunkName: "BlockVideo" */ '@/vue/components/blocks/Video.vue')),
        BlockMaps: defineAsyncComponent(() => import(/* webpackChunkName: "BlockMap" */ '@/vue/components/blocks/Maps.vue')),
        BlockRecommended: defineAsyncComponent(() => import(/* webpackChunkName: "Recommended" */ '@/vue/components/blocks/Recommended.vue')),

        //* OBJECTS
        Accordion: defineAsyncComponent(() => import(/* webpackChunkName: "Accordion" */ '@/vue/components/objects/Accordion.vue')),
        ContentSlider: defineAsyncComponent(() => import(/* webpackChunkName: "ContentSlider" */ '@/vue/components/objects/ContentSlider.vue')),
        ObjectRating: defineAsyncComponent(() => import(/* webpackChunkName: "ObjectRating" */ '@/vue/components/objects/Rating.vue')),
        InstagramFeed: defineAsyncComponent(() => import(/* webpackChunkName: "InstagramFeed" */ '@/vue/components/objects/InstagramFeed.vue')),
        RecommendedSlider: defineAsyncComponent(() => import(/* webpackChunkName: "RecommendedSlider" */ '@/vue/components/objects/RecommendedSlider.vue')),
        CategorySlider: defineAsyncComponent(() => import(/* webpackChunkName: "CategorySlider" */ '@/vue/components/objects/CategorySlider.vue')),
        PriceObject: defineAsyncComponent(() => import(/* webpackChunkName: "PriceObject" */ '@/vue/components/objects/PriceObject.vue')),

        //* SITE
        Catalog: defineAsyncComponent(() => import(/* webpackChunkName: "Catalog" */ '@/vue/components/Catalog.vue')),
        Banner: defineAsyncComponent(() => import(/* webpackChunkName: "Banner" */ '@/vue/components/objects/Banner.vue')),
        Search: defineAsyncComponent(() => import(/* webpackChunkName: "Search" */ '@/vue/components/Search.vue')),
        Overview: defineAsyncComponent(() => import(/* webpackChunkName: "Overview" */ '@/vue/components/Overview.vue')),
        SiteMenu: defineAsyncComponent(() => import(/* webpackChunkName: "SiteMenu" */ '@/vue/components/SiteMenu.vue')),
        SiteSelector: defineAsyncComponent(() => import(/* webpackChunkName: "SiteSelector" */ '@/vue/components/SiteSelector.vue')),
        SiteCart: defineAsyncComponent(() => import(/* webpackChunkName: "SiteCart" */ '@/vue/components/SiteCart.vue')),
        SiteFavorite: defineAsyncComponent(() => import(/* webpackChunkName: "SiteFavorite" */ '@/vue/components/SiteFavorite.vue')),
        FavoritePage: defineAsyncComponent(() => import(/* webpackChunkName: "FavoritePage" */ '@/vue/components/commerce/FavoritePage.vue')),
        SearchPage: defineAsyncComponent(() => import(/* webpackChunkName: "SearchPage" */ '@/vue/components/commerce/SearchPage.vue')),
        SiteLogin: defineAsyncComponent(() => import(/* webpackChunkName: "SiteLogin" */ '@/vue/components/SiteLogin.vue')),
        TrustBox: defineAsyncComponent(() => import(/* webpackChunkName: "TrustBox" */ '@/vue/components/objects/TrustBox.vue')),
      },
      computed: {
        ...mapState({
          isLoggedIn: (state: any) => state.commerce.commerceCart.isLoggedIn,
        }),
      },
      beforeMount() {
        // STATUS
        store.dispatch("status/closeSiteSelector");
        store.dispatch("status/closeVariantSelector");

        // SITE
        store.commit('app/setSiteUrl', window.sitedata.siteUrl);
        store.commit('app/setLanguage', window.sitedata.language);
        store.commit('translations/setSiteTranslations', window.sitedata.siteTranslations);

        // ASYNC
        axios.get(window.sitedata.siteUrl + 'api/qd-app/app/get-sitedata').then((res) => {
          store.dispatch('commerce/cart', res.data.cart)
          store.dispatch('app/favorites', res.data.favorites)
          store.dispatch('commerce/subscription', res.data.subscription)
          store.commit('app/setCsrfToken', res.data.csrf)
        })
      },

      setup() {
        return {
          modules: [Navigation, Pagination],
        };
      },
    });

  //* USE
	app.use(store);

  //* COMPONENTS
  // Swiper
  SwiperCore.use([Navigation, Pagination, EffectFade, Thumbs]);
	app.component('Swiper', Swiper);
	app.component('SwiperSlide', SwiperSlide);

  // Video background
	app.component('video-background', VideoBackground);

	//* MIXIN
  app.mixin(site);
	app.mixin(translations);

  //* Web components
  app.config.compilerOptions.isCustomElement = (tag) => tag === 'anyday-price-widget';

  //* MOUNT
  const vm = app.mount('#app');
  return vm;
};

// Execute async function
main().then((vm) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
