const state = () => ({
  siteTranslations: [],
});

// Getters
const getters = {};

// Actions
const actions = {};

// Mutations
const mutations = {
  setSiteTranslations(state, value) {
    state.siteTranslations = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
